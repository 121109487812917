import { HomeIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import React, { useState } from 'react';
import Button from 'src/components/input/Button';
import Input from 'src/components/input/Input';
import Modal, { ModalFooter } from 'src/components/Modal';
import Spinner from 'src/components/Spinner';
import { Order } from 'src/hooks/data/orders/useOrders.ts';
import useCurrentUser from 'src/hooks/data/users/useCurrentUser.ts';
import UserManager from 'src/tools/UserManager';
import DeliveryDateInput, { dateToString, DeliveryDateTypes, getRelativeDateOption, timeToString } from '../common/DeliveryDateInput.tsx';
import OrderModalHeader from '../common/OrderModalHeader.tsx';
import ModalOrderSummary from '../common/ModalOrderSummary.tsx';

type Props = {
  order: Order;
  open: boolean;
  setOpen: (open: boolean) => void;
}

/**
 * Modal for rescheduling an order.
 * Has inputs for the installation date and delivery date.
 */
export default function RescheduleOrderModal({
  order, open, setOpen }: Props) {

  // ------------- //
  // --- Hooks --- //
  // ------------- //

  const user = useCurrentUser();

  // -------------- //
  // --- States --- //
  // -------------- //

  const [installationDate, setInstallationDate] = useState<string | null>(
    order.installationDate ? moment(order.installationDate).utc().format("YYYY-MM-DD") : null
  );
  const [requestedDeliveryDate, setRequestedDeliveryDate] = useState<string | null>(order.requestedDelivery?.deliveryDate);
  const [deliveryDateType, setDeliveryDateType] = useState<DeliveryDateTypes>(
    order.requestedDelivery?.relativeToInstall
      ? (order.requestedDelivery?.relativeToInstall.amount == 0 ? DeliveryDateTypes.DAY_OF : DeliveryDateTypes.RELATIVE)
      : DeliveryDateTypes.SPECIFIED
  );

  const [deliveryTimeRangeStart, setDeliveryTimeRangeStart] = useState<string | null>(order.requestedDelivery?.deliveryWindow.start);
  const [deliveryTimeRangeEnd, setDeliveryTimeRangeEnd] = useState<string | null>(order.requestedDelivery?.deliveryWindow.end);

  const [relativeToInstall, setRelativeToInstall] = useState<{ amount: number, unit: "day" | "week" | "month" } | null>(order.requestedDelivery?.relativeToInstall);

  const [loading, setLoading] = useState<boolean>(false);

  // ----------------- //
  // --- Constants --- //
  // ----------------- //

  const canSubmit = [
    requestedDeliveryDate,
    deliveryTimeRangeStart,
    deliveryTimeRangeEnd,

    deliveryDateType === DeliveryDateTypes.RELATIVE || deliveryDateType === DeliveryDateTypes.DAY_OF
      ? relativeToInstall && installationDate
      : true,

  ].every(Boolean);

  // ---------------- //
  // --- Handlers --- //
  // ---------------- //

  /**
    * Cancel rescheduling the order.
    */
  function cancel() {
    setOpen(false);
  }

  /**
    * Handles submitting the reschedule action.
    * Submits the form data to the server.
    */
  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    if (!canSubmit) return;

    setLoading(true);

    let body = {
      orderId: order._id,

      installationDate: installationDate && moment(installationDate).utc().format("YYYY-MM-DD"),

      relativeToInstall: relativeToInstall,

      deliveryDate: moment(requestedDeliveryDate).utc().format("YYYY-MM-DD"),
      deliveryWindow: {
        start: deliveryTimeRangeStart,
        end: deliveryTimeRangeEnd
      }
    }

    try {
      var res = await UserManager.makeAuthenticatedRequest("/api/orders/reschedule", "POST", body);
    } catch (error) {
      console.error(error)
    }

    setLoading(false);
    setOpen(false);
  }

  /**
    * Handles the change event of the installation date input.
    * Updates delivery date if the delivery date type is relative.
    */
  function handleInstallationDateChange(value) {
    setInstallationDate(value ? moment(value).utc().format("YYYY-MM-DD") : value)

    if (deliveryDateType === DeliveryDateTypes.RELATIVE) {
      // Update requested delivery date based on the selected relative value
      if (relativeToInstall) {

        const resultingDate = moment(value).subtract(relativeToInstall.amount, relativeToInstall.unit).format("YYYY-MM-DD")

        setRequestedDeliveryDate(resultingDate)
      }
    } else if (deliveryDateType === DeliveryDateTypes.DAY_OF) {
      // Set requested delivery date to the same as the installation date
      setRequestedDeliveryDate(value ? moment(value).format("YYYY-MM-DD") : value)
    }
  }

  /**
   * Handles the change event of the delivery date type input.
   * Updates all states based on the selected delivery date type.
   * Resets everything to null. 
   * If setting to `DAY_OF`, sets relative to install info to 0 days.
   */
  function handleTypeChange(type: DeliveryDateTypes) {
    setDeliveryDateType(type)
    setRequestedDeliveryDate(type === DeliveryDateTypes.DAY_OF ? installationDate : null)
    setDeliveryTimeRangeStart(null)
    setDeliveryTimeRangeEnd(null)

    let newRelativeToInstall = null;
    if (type === DeliveryDateTypes.DAY_OF) {
      newRelativeToInstall = { amount: 0, unit: "day" }
    }
    setRelativeToInstall(newRelativeToInstall);

  }

  /**
   * Handles the change event of the requested delivery date input.
   * Sets `requestedDeliveryDate` to the selected date.
   */
  function handleDeliveryDateChange(value) {
    let date = moment(value, "YYYY-MM-DD").toISOString()
    setRequestedDeliveryDate(date);
  }

  /**
   * Handles the change event of the delivery time range start input.
   * Sets `deliveryTimeRangeStart` to the selected time.
   */
  function handleStartChange(value: string) {
    let date = moment(value, "HH:mm:ss.sssZ").toISOString()
    setDeliveryTimeRangeStart(date);
  }

  /**
   * Handles the change event of the delivery time range end input.
   * Sets `deliveryTimeRangeEnd` to the selected time.
   */
  function handleEndChange(value: string) {
    let date = moment(value, "HH:mm:ss.sssZ").toISOString()
    setDeliveryTimeRangeEnd(date);
  }

  /**
    * Handles the change event of the relative date option input.
    * Sets `relativeToInstall` to the selected relative date option.
    */
  function handleRelativeDateOptionChange(value) {
    let [amount, unit] = value.split("-");
    let newRelativeToInstall = {
      amount: +amount,
      unit: unit as "day" | "week" | "month",
    }
    setRelativeToInstall(newRelativeToInstall);
  }

  // ----------------- //
  // --- Rendering --- //
  // ----------------- //

  return (
    <Modal open={open} setOpen={setOpen}>
      <form
        onSubmit={handleSubmit}
      >
        {/* Body */}
        <div className="flex divide-x max-h-[80vh]">
          {/* Left Section */}
          <div className="flex flex-col w-full">
            <OrderModalHeader
              order={order}
              title="Reschedule Order"
            />

            <div className="flex flex-col px-10 py-6 text-sm font-medium divide-y">
              {
                user?.company?.type === "installer" && <div className="flex items-center gap-2 py-2">
                  <HomeIcon className="text-gray-700 stroke-2 w-7 h-7" />
                  <p>Install Date / Time</p>
                  <div className="ml-auto">
                    <Input
                      type="date"
                      name="installationDate"
                      value={installationDate}
                      onChange={handleInstallationDateChange}
                    />
                  </div>
                </div>
              }
              <div
                className="py-2"
              >
                <DeliveryDateInput
                  deliveryDateType={deliveryDateType}
                  requestedDeliveryDate={dateToString(requestedDeliveryDate)}
                  deliveryTimeRangeStart={timeToString(deliveryTimeRangeStart)}
                  deliveryTimeRangeEnd={timeToString(deliveryTimeRangeEnd)}
                  selectedRelativeDateOption={getRelativeDateOption(relativeToInstall)}
                  installationDate={installationDate ? dateToString(installationDate) : null}

                  onDeliveryDateTypeChange={handleTypeChange}
                  onRequestedDeliveryDateChange={handleDeliveryDateChange}
                  onDeliveryTimeRangeStartChange={handleStartChange}
                  onDeliveryTimeRangeEndChange={handleEndChange}
                  onSelectedRelativeDateOptionChange={handleRelativeDateOptionChange}
                />
              </div>
            </div>
          </div>
        </div>
        <ModalFooter>
          <div className="flex items-center justify-end gap-2">
            {
              !loading ? <Button
                type="submit"
                disabled={!canSubmit}
                variant="primary"
              >
                Confirm
              </Button>
                : <div className="flex items-center pr-7">
                  <Spinner size={20} />
                </div>
            }
            <Button
              variant="secondary"
              onClick={cancel}
            >
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  )
}

