import React, { Fragment } from 'react'
import SwitchCase from 'src/components/utils/SwitchCase.tsx'
import useConnections from 'src/hooks/data/connections/useConnections.ts'
import useLocations from 'src/hooks/data/locations/useLocations.ts'
import useLocationsByDistributor from 'src/hooks/data/locations/useLocationsByDistributor.ts'
import useMarkets from 'src/hooks/data/markets/useMarkets.ts'
import useMarketsByInstaller from 'src/hooks/data/markets/useMarketsByInstaller.ts'
import { Order } from 'src/hooks/data/orders/useOrders.ts'
import useCurrentUser from 'src/hooks/data/users/useCurrentUser.ts'
import useUsersByIds from 'src/hooks/data/users/useUsersByIds.ts'
import { MinorBadge } from '../OrderRowItem.tsx'
import ModalOrderSummary from './ModalOrderSummary.tsx'
import moment from 'moment'

type Props = {
  order: Order;
  title: string;
  subtitle?: string;

  showOtherPlace?: boolean;
}

/**
 * Component to display major order metadata.
 * Useful to put at the top of an order action modal.
 * Takes `title` and `subtitle` to display info about the modal's purpose.
 *
 * `showOtherPlace` is a prop to show the other place (market or location) that the order is shared with.
 * Meaning if a distributor is viewing the modal and `showOtherPlace` is true, it will show the market.
 * And vice versa for an installer, they would see the location.
 */
export default function OrderModalHeader({
  order, title, subtitle, showOtherPlace = false
}: Props) {

  // --- Hooks --- //

  const user = useCurrentUser();
  const companyType = user?.company?.type;

  const connections = useConnections(); // TODO: only get specific one? pass from parent?

  let connectionId = null;
  switch (companyType) {
    case "installer":
      connectionId = order.distributorId;
      break;
    case "distributor":
      connectionId = order.installerId;
      break;
  }
  const connectionName = connections
    ?.find(c => c.id === connectionId)
    ?.name;

  const markets = useMarketsByInstaller(order.installerId);
  const marketName = markets?.find(m => m._id === order.marketId)?.name;

  // format deliveryDate like "Friday, June 2 @ 8:00 AM - 9:00 AM" using order.requestedDelivery.deliveryDate and order.requestedDelivery.deliveryWindow.start and end
  const deliveryDate = moment(order.requestedDelivery?.deliveryDate).utc().format("dddd, MMMM D @") + " " + moment(order.requestedDelivery?.deliveryWindow.start).format("h:mm A") + " - " + moment(order.requestedDelivery?.deliveryWindow.end).format("h:mm A");

  const locations = useLocationsByDistributor(order.distributorId);
  const locationName = locations?.find(l => l._id === order.locationId)?.name;

  const usersMap = useUsersByIds([order.primaryContactId, order.installerOnSiteLeadId, ...order.sharedWithIds]);
  const users = usersMap ? Object.values(usersMap) : [];

  // Used in shich case to determine which place to show
  let placeToShow = companyType === "installer" ? "market" : "location";
  if (showOtherPlace) {
    placeToShow = placeToShow === "market" ? "location" : "market";
  }

  return (
    <div
      className='w-full'
    >
      {/* Header */}
      <div className="flex items-center justify-between pb-3">
        <div className="flex flex-col">
          <div className="text-base font-semibold">{title}</div>
          {subtitle && <div className="text-sm font-normal text-gray-500">{subtitle}</div>}
        </div>
      </div >

      <ModalOrderSummary
        order={order}
        companyType={companyType}
        connectionName={connectionName}
        marketName={marketName}
        deliveryDate={deliveryDate}
        sharedWithUsers={users}
        locationName={locationName}
      />

    </div>
  )
}

/**
  * Super simple loading component.
  * Pulses "Loading"
  */
function Loading({ }) {
  return <span className="animate-pulse">Loading</span>
}
