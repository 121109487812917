import React, { useEffect, useState } from 'react'
import Modal, { ModalFooter } from 'src/components/Modal';
import { Order, OrderStatusLabels } from 'src/hooks/data/orders/useOrders.ts';
import OrderModalHeader from '../common/OrderModalHeader.tsx';
import Button from 'src/components/input/Button.js';
import { addressToString, getDeliveryDateString, MinorBadge } from '../OrderRowItem.tsx';
import classNames from 'src/tools/classNames.js';
import OrderNotes from '../common/OrderNotes.tsx';
import Input from 'src/components/input/Input';
import { HomeIcon, TruckIcon } from '@heroicons/react/24/outline';
import AddressHelper from 'src/utils/addressHelper.ts';
import { dateToString } from '../common/DeliveryDateInput.tsx';
import moment from 'moment';
import useCurrentUser from 'src/hooks/data/users/useCurrentUser.ts';
import useConnections from 'src/hooks/data/connections/useConnections.ts';
import useMarkets from 'src/hooks/data/markets/useMarkets.ts';
import useLocations from 'src/hooks/data/locations/useLocations.ts';
import SwitchCase from 'src/components/utils/SwitchCase.tsx';
import UserManager from 'src/tools/UserManager';
import Spinner from 'src/components/Spinner';
import { Note } from 'src/hooks/data/orders/useOrders';
import StatusDisplay from '../common/StatusDisplay.tsx';

type Props = {
  order: Order;
  open: boolean;
  setOpen: (open: boolean) => void;
  onNoteAdded?: (note: Note) => void;
}

/**
 * Order action modal with a simple input for adding a note to the order.
 */
export default function AddNoteModal({
  open, setOpen, order, onNoteAdded
}: Props) {

  // -------------- //
  // --- States --- //
  // -------------- //

  const [note, setNote] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  // ------------- //
  // --- Hooks --- //
  // ------------- //

  const user = useCurrentUser();
  const companyType = user?.company?.type;

  const connections = useConnections(); // TODO: only get specific one? pass from parent?
  let connectionId = null;
  switch (companyType) {
    case "installer":
      connectionId = order.distributorId;
      break;
    case "distributor":
      connectionId = order.installerId;
      break;
  }
  const connectionName = connections
    ?.find(c => c.id === connectionId)
    ?.name;

  const markets = useMarkets()
  const marketName = markets?.find(m => m._id === order.marketId)?.name;

  const locations = useLocations();
  const locationName = locations?.find(l => l._id === order.locationId)?.name;

  // ------------------- //
  // --- Use Effects --- //
  // ------------------- //

  // Reset note when modal is closed
  useEffect(() => {
    if (!open) {
      setNote("");
    }
  }, [open])

  // ----------------- //
  // --- Functions --- //
  // ----------------- //

  /**
   * Submits the note to the server.
   */
  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    if (!note) return; // Required field

    setLoading(true);

    let body = {
      orderId: order._id,
      note: note,
    }

    try {
      var res = await UserManager.makeAuthenticatedRequest("/api/orders/add-note", "POST", body);
    } catch (error) {
      console.error(error);
    }

    // Callback if note was added
    if (res.data.status === "ok") {
      let note = res.data.note as Note;
      onNoteAdded?.(note);
    }

    setLoading(false)
    close()
  }

  /**
    * Close the modal
    */
  function close() {
    setOpen(false);
    setNote("");
    setLoading(false);
  }

  /**
   * Update the note
   */
  function handleNoteChange(value: string) {
    setNote(value)
  }

  // -------------- //
  // --- Consts --- //
  // -------------- //

  const deliveryString = getDeliveryDateString(order)
  const installString = order.installationDate ? moment(order.installationDate).utc().format('ddd M/D') : null

  // -------------- //
  // --- Render --- //
  // -------------- //

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      wide
    >
      <form
        onSubmit={handleSubmit}
        className=""
      >
        <OrderModalHeader
          title="Add Note"
          order={order}
        />
        <div className="overflow-y-auto divide-y max-h-[80vh]">

          {/* Input & Notes */}
          <Section>
            <h2 className="text-base font-semibold">Add Note</h2>
            <div className="px-2 pt-1 pb-6">
              <Input
                type="textarea"
                placeholder="Enter a note..."
                value={note}
                onChange={handleNoteChange}
              />
            </div>
            <h2 className="text-base font-semibold">Notes ({order.notes?.length})</h2>
            <div className="px-2 pt-1">
              <OrderNotes order={order} />
            </div>
          </Section>
        </div>
        {/* Footer */}
        <ModalFooter>
          <div className="flex items-center justify-end gap-2">
            {
              !loading ? <Button
                variant="primary"
                type="submit"
                disabled={!note}
              >
                Add Note
              </Button>
                : <div className="flex items-center pr-7">
                  <Spinner size={20} />
                </div>
            }
            <Button
              variant="secondary"
              onClick={close}
            >
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  )
}

type SectionProps = {
  children?: React.ReactNode
  noTopPadding?: boolean
}

/**
  * Styles a section of the modal
  */
function Section({ children, noTopPadding = false }: SectionProps) {
  return <div
    className={classNames(
      noTopPadding ? "pb-4" : "py-4",
    )}
  >
    {children}
  </div>
}

/**
  * Super simple loading component.
  * Pulses "Loading"
  * TODO: move to a shared with OrderModalHeader
  */
function Loading({ }) {
  return <span className="animate-pulse">Loading</span>
}
