import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react'
import Modal from 'src/components/Modal'
import { AgreementStatus, Order } from 'src/hooks/data/orders/useOrders.ts';
import useConnections from 'src/hooks/data/connections/useConnections.ts';
import useLocations, { LocationType } from 'src/hooks/data/locations/useLocations.ts';
import useCurrentUser from 'src/hooks/data/users/useCurrentUser.ts';
import useS3URLs from 'src/hooks/data/files/useS3.ts';
import S3 from 'src/tools/S3/s3.ts';
import UserManager from 'src/tools/UserManager';
import { MarketType } from "src/contexts/forecast/history/ForecastHistoryContext";
import Dropdown from 'src/components/input/Dropdown';
import {
    WarehouseIcon,
    Trash2Icon
} from 'lucide-react';
import {
    PaperClipIcon,
    CheckCircleIcon
} from '@heroicons/react/20/solid';
import Button from 'src/components/input/Button';
import Input from 'src/components/input/Input';
import Spinner from 'src/components/Spinner';
import OrderModalHeader from '../common/OrderModalHeader.tsx';

type Props = {
    order: Order;
    open: boolean;
    setOpen: (open: boolean) => void;
}

/**
 * Add pick ticket modal. Has a location select and file upload.
 */
export default function AddPickTicketModal({
    order: order_prop,
    open,
    setOpen: setOpen_prop
}: Props) {

    // ------------------------------------ //
    // --- States, Hooks, Memos, & Refs --- //
    // ------------------------------------ //

    const currentUser = useCurrentUser();

    const [loading, setLoading] = useState<boolean>(false);

    const [order, setOrder] = useState(structuredClone(order_prop));
    const [quoteFile, setQuoteFile] = useState<File | null>(null);

    const connections = useConnections();
    const installerName = connections?.find(c => c.id === order.installerId)?.name;

    const [markets, setMarkets] = useState<MarketType[] | null>(null);
    const [marketName, setMarketName] = useState<string | null>(null);

    const locations = useLocations();
    const [locationOptions, setLocationOptions] = useState<{ label: string, value: string }[][] | null>([]);
    const [selectedLocation, setSelectedLocation] = useState<LocationType | null>(null);

    const [quoteFileName, setQuoteFileName] = useState<string | null>(null);

    const files = useS3URLs(
        order?.quote?.file?.filePath ? [order.quote.file.filePath] : []
    );
    const quoteLink = files.length ? files[0].getObjectSignedUrl : null;

    const hiddenPickTicketFileInput = useRef<HTMLInputElement>(null);
    const [pickTicketFile, setPickTicketFile] = useState<File | null>(null);

    const [note, setNote] = useState<string | null>(null);

    // ------------------ //
    // --- Use Effect --- //
    // ------------------ //

    useEffect(() => {
        if (locations) {
            setLocationOptions([locations.map(location => {
                return {
                    label: location.name,
                    value: location._id
                }
            })]);
        }
    }, [locations]);

    useEffect(() => {
        const installerIds = connections?.map(c => c.id).join(',');

        if (installerIds) {
            UserManager.makeAuthenticatedRequest(
                `/api/markets/distributor/find?installerIds=${installerIds}`,
                'GET',
            )
                .then((response) => {
                    setMarkets(response.data.markets.map((market) => {
                        return {
                            id: market._id,
                            installerId: market.installer,
                            name: market.name
                        }
                    }))

                    setMarketName(markets?.find(m => m.installerId === order.installerId)?.name)

                })
                .catch((error) => {
                    console.error(error)
                })
        }

    }, [connections])

    useEffect(() => {
        setQuoteFileName(
            order?.quote?.file?.filePath
                ? order.quote.file.filePath.split('/').pop()
                : null
        );
    }, [order]);

    // Set order when prop changes
    useEffect(() => {
        setOrder(structuredClone(order_prop));
    }, [order_prop]);

    // format like "Tues, Oct 15 @ 9:00 AM - 11:00 AM"
    var deliveryDateString = moment(order.requestedDelivery.deliveryDate).format("ddd, MMM D");
    deliveryDateString = deliveryDateString + ` @ ${moment(order.requestedDelivery.deliveryWindow.start).format("h:mm A")} - ${moment(order.requestedDelivery.deliveryWindow.end).format("h:mm A")}`;

    var orderAddressString = order.orderAddress.line1 + (order.orderAddress.line2 ? order.orderAddress.line2 + " " : " ")
        + order.orderAddress.state + ', ' + order.orderAddress.postalCode

    /**
     * Submit the form to add the pick ticket to the order.
     */
    async function handleSubmit(event) {
        event.preventDefault();
        setLoading(true);
        // Upload pick ticket file to S3
        const pickTicketFileName = `pick_tickets/${currentUser.company.id}/${order._id}/${pickTicketFile.name}`;
        const pickTicketUploadSuccess = await S3.upload(pickTicketFile, pickTicketFileName);

        if (!pickTicketUploadSuccess) {
            console.error("Failed to upload pick ticket file to S3");
            setLoading(false);
            return;
        }

        try {
          var response = await UserManager.makeAuthenticatedRequest(
              `/api/orders/add-pick-ticket`,
              'POST',
              {
                orderId: order._id,
                pickTicketPath: pickTicketFileName,
                locationId: selectedLocation,
                note: note
              }
            )
          } catch (error) {
            console.error("Failed to add pick ticket to order", error);
          }
          setOpen_prop(false);
          setLoading(false);
    }

    /**
     * Clicks the hidden pick ticket file input. This opens the file select dialog.
     */
    function handlePickTicketFileClick() {
        hiddenPickTicketFileInput.current.click();
    }

    /**
     * Grabs the file when a file is selected.
     */
    function handlePickTicketFileChange(event) {
        if (event.target.files?.length) {
            setPickTicketFile(event.target.files[0]);
        }
    }

    /**
     * Removes the pick ticket file from the order.
     */
    function handlePickTicketFileRemove() {
        setPickTicketFile(null);
        if (hiddenPickTicketFileInput.current) {
            hiddenPickTicketFileInput.current.value = null;
        }
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen_prop}
            wide
        >
            <form
                onSubmit={handleSubmit}
                className='flex flex-col lg:min-w-[550px]'
            >
                <OrderModalHeader
                    order={order}
                    title="Hand off to Packaging Team"
                    subtitle="Add location and pick ticket to trigger packaging process"
                />

                <div
                    className='flex flex-col items-center justify-start px-4 -mx-4 border-b divide-y divide-gray-300 sm:-mx-6 sm:px-6'
                >
                    <div
                        className='flex flex-row items-center justify-center w-full gap-4 py-4'
                    >
                        <div
                            className='flex flex-row items-center justify-center gap-2'
                        >
                            <WarehouseIcon
                                size={24}
                                color='#374151'
                            />
                            <div
                                className='text-sm font-medium'
                            >
                                Location
                            </div>
                        </div>
                        <Dropdown
                            options={locationOptions}
                            selectedValue={selectedLocation}
                            onSelected={(location) => {
                                setSelectedLocation(location?.value);
                            }}
                            placeholder='Select a Location'
                        />
                    </div>

                    <div
                        className='flex flex-row items-center justify-center w-full gap-2 pb-4 border-none'
                    >
                        <div
                            className='text-sm font-semibold text-gray-600 align-middle'
                        >
                            Approved Quote:
                        </div>
                        <div
                            className='flex flex-row items-center justify-center gap-2 cursor-pointer'
                            onClick={() => {
                                if (quoteLink) {
                                    // Open quote in new tab
                                    window.open(quoteLink, '_blank');
                                }
                            }}
                        >
                            <div
                                className='overflow-hidden text-sm font-normal max-w-52 text-nowrap text-ellipsis'
                            >
                                {quoteFileName}
                            </div>
                            <div
                                className='text-sm font-semibold text-primary-green'
                            >
                                View
                            </div>
                        </div>
                    </div>

                    {pickTicketFile
                        ? (
                            <div
                                className='flex flex-row items-center justify-between w-full gap-4 px-24 py-5'
                            >
                                <div
                                    className='flex flex-row items-center justify-center gap-2 align-middle'
                                >
                                    <CheckCircleIcon
                                        height={28}
                                        width={28}
                                        color='#1cb7be'
                                    />
                                    <div
                                        className='text-sm text-gray-500 font-semibod'
                                    >
                                        Pick Ticket
                                    </div>
                                </div>
                                <div
                                    className='flex flex-row items-center justify-center gap-2 align-middle cursor-pointer'
                                    // view pick ticket
                                    onClick={() => {
                                        // Open pick ticket in new tab 
                                        window.open(URL.createObjectURL(pickTicketFile), '_blank');
                                    }}
                                >
                                    <div
                                        className='overflow-hidden text-sm font-normal text-gray-600 align-middle max-w-52 text-ellipsis text-nowrap'
                                    >
                                        {pickTicketFile.name}
                                    </div>
                                    <div
                                        className='text-sm font-semibold text-primary-green'
                                    >
                                        View
                                    </div>
                                </div>
                                <div
                                    className='cursor-pointer'
                                    onClick={handlePickTicketFileRemove}
                                >
                                    <Trash2Icon
                                        height={20}
                                        width={20}
                                        color='#d4246a'
                                    />
                                </div>
                            </div>
                        )
                        : (
                            <div
                                className='flex items-center justify-center w-full py-4'
                            >
                                <input
                                    type="file"
                                    onChange={handlePickTicketFileChange}
                                    ref={hiddenPickTicketFileInput}
                                    className="hidden"
                                />
                                <Button
                                    onClick={() => {
                                        handlePickTicketFileClick();
                                    }}
                                    variant='primary-green'
                                    icon={<PaperClipIcon height={20} width={20} color='#ffffff' />}
                                >
                                    Upload Pick Ticket
                                </Button>
                            </div>
                        )}

                    <div
                        className='flex flex-row items-start justify-center w-full gap-4 px-6 py-4'
                    >
                        <div
                            className='text-sm font-medium text-gray-500'
                        >
                            Notes:
                        </div>
                        <div
                            className='w-full'
                        >
                            <Input
                                type='textarea'
                                placeholder='Add notes...'
                                value={note}
                                onChange={(value) => {
                                    setNote(value);
                                }}
                                className='resize-none'
                            />
                        </div>
                    </div>

                </div>
                <div className="flex justify-end w-full gap-2 pt-4">
                    {
                      loading ? <div className="flex items-center pr-7">
                        <Spinner size={20} />
                      </div> :
                      <Button
                          type="submit"
                          variant="primary"
                          disabled={!selectedLocation || !pickTicketFile}
                      >
                          Confirm
                      </Button>
                    }
                    <Button
                        variant="secondary"
                        onClick={() => setOpen_prop(false)}
                    >
                        Cancel
                    </Button>
                </div>
            </form>
        </Modal >
    )
}
