import moment from 'moment';
import React from 'react';
import Button from 'src/components/input/Button';
import Modal, { ModalFooter } from 'src/components/Modal';
import Spinner from 'src/components/Spinner';
import { Order, TimestampTypes } from 'src/hooks/data/orders/useOrders.ts';
import UserManager from 'src/tools/UserManager';
import OrderModalHeader from '../common/OrderModalHeader.tsx';
import { OrderAction } from '../common/OrderActionButton.tsx';
import { getDeliveryDateString } from '../OrderRowItem.tsx';
import OrderTimeline from '../OrderTimeline.tsx';

type Props = {
  order: Order;
  open: boolean;
  setOpen: (open: boolean) => void;
  onAction: (action: OrderAction) => void;
}

/**
 * Simple modal to review the new delivery date when an order is rescheduled.
 */
export default function ReviewDateModal({
  order, open, setOpen, onAction
}: Props
) {
  // -------------- //
  // --- States --- //
  // -------------- //

  const [loading, setLoading] = React.useState<boolean>(false);

  // ----------------- //
  // --- Functions --- //
  // ----------------- //

  /**
   * Submits the approval of the new delivery date.
   */
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      var res = await UserManager.makeAuthenticatedRequest("/api/orders/approve-reschedule", "POST", {
        orderId: order._id
      });
    } catch (error) {
      console.error(error);
    }
    close();
    setLoading(false);
  }

  /**
   * Switch to rescheduling to change date
   */
  function handleChangeDate() {
    close();
    onAction(OrderAction.RESCHEDULE_ORDER);
  }

  /**
    * Closes the modal.
    */
  function close() {
    setOpen(false);
  }

  // -------------- //
  // --- Consts --- //
  // -------------- //

  // Activity timeline filters
  const timelineTimestamps = [
    TimestampTypes.AGREEMENT_CHANGED,
    TimestampTypes.NOTE_ADDED,
  ]
  const excludeNoteTypeFilter = [
    "material", // Material note is displayed in the agreement timestamp so no need to show it twice
  ]

  return (
    <Modal
      wide
      open={open}
      setOpen={setOpen}
    >
      <form
        onSubmit={handleSubmit}
      >
        <div className="flex divide-x max-h-[80vh] -ml-6">

          {/* Order */}
          <div className="flex flex-col pr-6 overflow-y-scroll">
            <div
              className='pl-4'
            >
              <OrderModalHeader
                title="Review New Delivery Date"
                subtitle="Approve or change delivery date"
                order={order}
              />
            </div>

            <div
              className='ml-6'
            >
              <h1 className="pt-4 text-xl font-semibold">New Delivery Date</h1>
              <div
                className="flex gap-4 py-3 font-normal"
              >
                <div>
                  <div className="font-medium">Install Date</div>
                  <div>
                    {order.installationDate ? moment(order.installationDate).utc().format("MMM DD, YYYY") : "--"}
                  </div>
                </div>
                <div>
                  <div className="font-medium">Delivery Date</div>
                  <div>
                    {getDeliveryDateString(order)}
                  </div>
                </div>
              </div>

            </div>

          </div>

          {/* Activity */}
          <div className="flex flex-col px-6">
            <div className="pb-3 text-sm font-medium">Adjustment Timeline</div>
            <div className="overflow-y-scroll grow">
              <OrderTimeline
                orders={[order]}
                timestampFilter={timelineTimestamps}
                hideOrderLink
                excludeNoteTypeFilter={excludeNoteTypeFilter}
              />
            </div>
          </div>
        </div>


        <ModalFooter>
          <div className="flex items-center justify-end gap-2">
            {!loading ?
              <React.Fragment>
                <Button
                  variant="primary"
                  onClick={handleChangeDate}
                >
                  Change Date
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                >
                  Approve
                </Button>
              </React.Fragment>
              : <div className="flex items-center pr-7">
                <Spinner size={20} />
              </div>}
            <Button
              variant="secondary"
              onClick={close}
            >
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </form>
    </Modal >
  )
}
