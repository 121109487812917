import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import {
  ArchiveBoxIcon,
  ChevronDownIcon,
  DocumentDuplicateIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";
import { Fragment } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const optionsExample = [
  [
    {
      label: "Edit",
      icon: PencilSquareIcon,
      value: "edit",
    },
    {
      label: "Duplicate",
      icon: DocumentDuplicateIcon,
      value: "duplicate",
    },
  ],
  [
    {
      label: "Archive",
      icon: ArchiveBoxIcon,
      value: "archive",
    },
    {
      label: "Move",
      icon: null,
      value: "move",
    },
  ],
];

export default function Dropdown({
  options = [],
  selectedValue = null,
  placeholder = "Select an option",
  bare = false,
  justifyLeft = false,
  above = false,
  label = null,
  wide = false,
  bold = false,
  highlightOnEmpty = false,
  className = "",
  onSelected = (item) => { },
  disabled = false,
}) {
  // Find option based on selected value
  const selectedOption = options
    .flat()
    .find((option) => option.value === selectedValue);

  return (
    <Menu
      as="div"
      className={classNames(
        className,
        "relative text-left",
        wide ? "block" : "inline-block"
      )}
    >
      <div>
        {label && (
          <label
            htmlFor="options-menu"
            className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-200"
          >
            {label}
          </label>
        )}
        <MenuButton
          type="button"
          disabled={disabled}
          className={classNames(
            bare
              ? ""
              : "bg-white dark:bg-gray-700 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-600 data-[disabled]:bg-gray-100 ring-1 ring-inset focus-visible:outline-primary-green shadow-sm px-3" +
              (highlightOnEmpty && !selectedOption
                ? " ring-secondary-orange"
                : " ring-gray-300"),
            "inline-flex w-full gap-x-1.5 rounded-md  py-2 text-sm whitespace-nowrap",
            bold ? "font-semibold" : "font-normal",
            wide ? "justify-between" : "justify-center",
            placeholder && !selectedOption ? "text-gray-400" : "text-gray-900",
          )}
        >
          <div
            className="overflow-hidden text-ellipsis"
          >
            {selectedOption ? selectedOption.label : placeholder}
          </div>
          <ChevronDownIcon
            className="w-5 h-5 -mr-1 text-gray-400"
            aria-hidden="true"
          />
        </MenuButton>
      </div>

      {/* BUG: HeadlessUI has a known issue with this transition. See their #3476 or #3507. Reimplement when resolved */}
      {/* <Transition */}
      {/*   as={Fragment} */}
      {/*   enter="transition ease-out duration-100" */}
      {/*   enterFrom="transform opacity-0 scale-95" */}
      {/*   enterTo="transform opacity-100 scale-100" */}
      {/*   leave="transition ease-in duration-75" */}
      {/*   leaveFrom="transform opacity-100 scale-100" */}
      {/*   leaveTo="transform opacity-0 scale-95" */}
      {/* > */}
      <MenuItems
        className={classNames(
          justifyLeft ? "left-0" : "right-0",
          above ? "bottom-full mb-2" : "top-full",
          "absolute mt-2 w-56 origin-top-right divide-y divide-gray-100 dark:divide-gray-600 rounded-md bg-white dark:bg-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10 max-h-[200px] overflow-y-auto"
        )}
      >
        {options.map((group, groupIndex) => (
          <div key={groupIndex} className="py-1">
            {group.map((item, itemIndex) => (
              <MenuItem key={itemIndex}>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      active ? "bg-gray-100 dark:bg-gray-500 text-gray-900 dark:text-white" : "text-gray-700 dark:text-white",
                      "w-full group flex items-center px-4 py-2 text-sm"
                    )}
                    onClick={() => onSelected(item)}
                  >
                    {item.icon && (
                      <item.icon
                        className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-200"
                        aria-hidden="true"
                      />
                    )}
                    {item.label}
                  </button>
                )}
              </MenuItem>
            ))}
          </div>
        ))}
      </MenuItems>
      {/* </Transition> */}
    </Menu>
  );
}
