import React, { useContext, useState } from 'react'
import { AlertType } from 'src/components/feedback/Alert.tsx'
import Alerts from 'src/components/feedback/Alerts.tsx'
import Button from 'src/components/input/Button'
import FileUpload from 'src/components/input/FileUpload.tsx'
import Modal, { ModalFooter } from 'src/components/Modal'
import Spinner from 'src/components/Spinner'
import UserContext, { UserContextType } from 'src/contexts/user/UserContext.tsx'
import { Order } from 'src/hooks/data/orders/useOrders.ts'
import S3 from 'src/tools/S3/s3.ts'
import UserManager from 'src/tools/UserManager'
import OrderModalHeader from '../common/OrderModalHeader.tsx'

type Props = {
  order: Order,
  open: boolean,
  setOpen: (open: boolean) => void,
}

const SUPPORT_EMAIL = "support@toa.energy"

/**
 * A modal to upload a new pick ticket for an order.
 * Simple file input to upload a new pick ticket.
 */
export default function UploadPickTicketModal({
  order, open, setOpen
}: Props) {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [alerts, setAlerts] = useState<AlertType[]>([]);

  const { user } = useContext<UserContextType>(UserContext);

  /**
    * Handles uploading a new pick ticket.
    */
  async function uploadPickTicket() {
    setLoading(true);

    // TODO: move file name definition  to backend
    const pickTicketFileName = `pick_tickets/${user.company.id}/${order._id}/${file.name}`;
    let success = await S3.upload(file, pickTicketFileName);

    if (!success) {
      setAlerts((prev) => {
        return [...prev, {
          severity: "error",
          title: "Upload Failed",
          subtext: `Failed to upload pick ticket. Please try again. If this continues to happen, please contact us at ${SUPPORT_EMAIL}.`,
          stamp: Date.now(),
        }]
      });
      setLoading(false);
      return;
    }

    let body = {
      orderId: order._id,
      pickTicketPath: pickTicketFileName,
      updateStatus: false,
      keepLocation: true,
    };

    // TODO: update pick ticket used in order details page and in order list
    // and probably in the retrieval endpoint? order list shows the right name but
    // wrong link. order details page shows both wrong.
    try {
      var res = await UserManager.makeAuthenticatedRequest(`/api/orders/add-pick-ticket`, "POST", body)
    } catch (err) {
      setAlerts((prev) => {
        return [...prev, {
          severity: "error",
          title: "Upload Failed",
          subtext: `Failed to update order. Please try again. If this continues to happen, please contact us at ${SUPPORT_EMAIL}.`,
          stamp: Date.now(),
        }]
      });
      console.error(err);
      setLoading(false);
      return;
    }

    setOpen(false);
    setLoading(false);
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      wide
    >
      <div
        className='lg:min-w-[550px]'
      >
        <Alerts
          alerts={alerts}
          onDismiss={(a: AlertType) => {
            setAlerts(prev => {
              return prev.filter((alert) => alert.stamp !== a.stamp)
            })
          }}
        />

        <OrderModalHeader
          title="Upload New Pick Ticket"
          order={order}
        />
        <div className="py-4">
          <FileUpload
            uploadText="New Pick Ticket"
            uploadedText="New Pick Ticket"
            onFileChange={setFile}
            buttonVariant="primary"
          />
        </div>
        <ModalFooter>
          <div className="flex items-center justify-end gap-2">
            {
              !loading
                ? <Button
                  variant="primary"
                  onClick={uploadPickTicket}
                  disabled={!file}
                >
                  Upload
                </Button>
                : <div className="flex items-center pr-7">
                  <Spinner size={20} />
                </div>
            }
            <Button
              variant="secondary"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </div>
    </Modal>
  )
}
